<template>

        <!--<v-app-bar dense absolute text >-->
            <v-tabs slot="extension"
                    dense
                    v-model="settingsmodel"
                    slider-color="orange">
                <v-tab :href="'#GeneralSettings'">
                    General
                </v-tab>
                <!--<v-tab :href="'#AccountSettings'">
              Account
            </v-tab>
            <v-tab :href="'#SecuritySettings'">
              Security
            </v-tab>-->
                <v-tab :href="'#NotificationSettings'">
                    Notifications
                </v-tab>

                <v-tab-item :value="'GeneralSettings'">
                    <GeneralSettings></GeneralSettings>
                </v-tab-item>
                <v-tab-item :value="'AccountSettings'">
                    <AccountSettings></AccountSettings>
                </v-tab-item>
                <v-tab-item :value="'SecuritySettings'">
                    <SecuritySettings></SecuritySettings>
                </v-tab-item>
                <v-tab-item :value="'NotificationSettings'">
                    <NotificationSettings></NotificationSettings>
                </v-tab-item>
            </v-tabs>
        <!--</v-app-bar>-->

</template>

<script>

  export default {
    components: {
        GeneralSettings: () => import('./generalSettings/generalSettings.vue'),
        AccountSettings: () => import('./accountSettings/accountSettings.vue'),
        SecuritySettings: () => import('./securitySettings/securitySettings.vue'),
        NotificationSettings: () => import('./notificationSettings/notificationSettings.vue') 
    },
    data() {
      return {
        saved: false,
        settingsmodel:'',
      }
    },
    methods: {
      selectSettings: function (item) {
        switch(item){
          case "general":
            this.settingsmodel = "GeneralSettings"
            break;
          case "account":
            this.settingsmodel = "AccountSettings"
            break;
          case "security":
            this.settingsmodel = "SecuritySettings"
            break;
          case "notification":
            this.settingsmodel = "NotificationSettings"
            break;
        }
      }
    },
    beforeMount() {
      //this.selectSettings(this.$route.params.itemId)
      this.selectSettings('general')
    }
  }

</script>
